export const wrapperStyles = {
  fontFamily: "body",
  fontSize: 2,
  lineHeight: 3,
  p: {
    m: 0,
    mb: 3,
    a: {
      textDecoration: "none"
    }
  }
};
