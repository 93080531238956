import { SxStyleProp } from "theme-ui";

export const wrapperStyles: SxStyleProp = {
  display: "flex",
  flexDirection: "column",
  p: 0,
  mb: 4
};

export const labelStyles: SxStyleProp = {
  fontFamily: "heading",
  fontSize: 1,
  lineHeight: 1,
  pt: 0,
  px: 4,
  pb: 2,
  userSelect: "none"
};
